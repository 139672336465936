<template>
  <div class="workspace__container__view">
    <div class="workspace__container__view__header">
      <div class="workspace__container__view__header__actions">
        <button @click="createOrEdit()">{{ $t("workspace.ad_agency.create_category") }}</button>
      </div>
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <input type="text" v-model="filterModel.code" :placeholder="`${$t('workspace.ad_agency.filter_for')} ${$t('workspace.ad_agency.code')}`">
          </div>
          <div class="settings-input">
            <input type="text" v-model="filterModel.name" :placeholder="`${$t('workspace.ad_agency.filter_for')} ${$t('workspace.ad_agency.name')}`">
          </div>
          <div class="settings-input">
            <select v-model="filterModel.adAgencyGroupId">
              <option :value="0">{{$t('workspace.ad_agency.filter_for')}} {{$t('workspace.ad_agency.ad_agency_group')}}</option>
              <option v-for="agency in listAgenciesGroup" :value="agency.id" :key="agency.id">{{agency.code}} - {{agency.name}}</option>
            </select>
          </div>
          <div class="settings-input">
            <input type="text" v-model="filterModel.taxCode" :placeholder="`${$t('workspace.ad_agency.filter_for')} ${$t('workspace.ad_agency.taxCode')}`">
          </div>
          <button 
            :disabled="filterModel.code == '' && filterModel.name == '' && filterModel.adAgencyGroupId == 0 && filterModel.taxCode == ''" 
            @click="filterModel.name = ''; filterModel.code = ''; filterModel.adAgencyGroupId = 0; filterModel.taxCode = ''">{{ $t("workspace.clean_filter") }}</button>
        </div>
      </div>
    </div>

    <table class="workspace__container__view__table">
      <thead>
        <tr>
          <th>{{$t('workspace.ad_agency.code')}}</th>
          <th>{{$t('workspace.ad_agency.name')}}</th>
          <th>{{$t('workspace.ad_agency.ad_agency_group')}}</th>
          <th>{{$t('workspace.ad_agency.taxCode')}}</th>
          <th class="actions">{{$t('workspace.ad_agency.actions')}}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="listAgencies(filterModel).length > 0">  
          <tr v-for="item in listAgencies(filterModel)" :key="item.code">
            <td v-html="highlightText(item.code, filterModel.code)"></td>
            <td v-html="highlightText(item.name, filterModel.name)"></td>
            <td v-html="highlightText(agenciesGroup(item.adAgencyGroupId), agenciesGroup(filterModel.adAgencyGroupId))"></td>
            <td v-html="highlightText(item.taxCode, filterModel.taxCode)"></td>
            <td class="actions">
              <i class="material-icons" @click="createOrEdit(item)">edit</i>
              <i class="material-icons" @click="deleteItem(item)">delete</i>
            </td>
          </tr>
        </template>
        <tr v-if="listAgencies(filterModel).length == 0 && !isFilterActive">
          <td colspan="5">{{$t('workspace.not_have_loaded')}} <strong>{{ $t("left_menu.commercial_config.ad_agency") }}</strong>. {{$t('workspace.please')}} <strong style="text-decoration: underline; cursor: pointer" @click="createOrEdit()">{{ $t("workspace.ad_agency.create_category") }}</strong></td>
        </tr>
        <tr v-if="listAgencies(filterModel).length == 0 && isFilterActive">
          <td colspan="5">{{$t('workspace.none_results_with_filters')}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex';
import { computed, onMounted } from '@vue/runtime-core';
import highlightText from '@/plugins/Mixin/highlightText.js';

export default {
  mixins: [highlightText],
  setup() {
    const store = useStore();
    const listAgencies = computed(() => filterModel => store.getters.adAgency_agencies(filterModel));
    const listAgenciesGroup = computed(() => store.getters.adAgencyGroup_agencies({name: '', code: ''}));    
    const isFilterActive = computed(() => filterModel.value.code != '' || filterModel.value.name != '' || filterModel.value.adAgencyGroupId != 0 || filterModel.value.taxCode != '');
    const filterModel = ref({
      code: '',
      name: '',
      adAgencyGroupId: 0,
      taxCode: ''
    });

    onMounted(async () => {
      store.dispatch('loading_setActive', true);
      await store.dispatch('adAgency_getAll');
      await store.dispatch('adAgencyGroup_getAll');
      store.dispatch('loading_setActive', false);
    });

    async function createOrEdit(item) {
      await store.dispatch('modal_setActive', {active: 'COMMERCIAL-CONFIG_AD-AGENCY_CREATE-EDIT', props: item});
    }
    
    async function deleteItem(item) {
      const modelDelete = {...item};
      modelDelete.adAgencyGroupId = this.agenciesGroup(item.adAgencyGroupId);
      await store.dispatch('modal_setActive', {active: 'COMMON_DELETE', props: modelDelete, actionDelete: 'adAgency_delete'});
    }

    function agenciesGroup(id) {
      const item = listAgenciesGroup.value.find(agency => agency.id == id);
      return item ? `${item.code} - ${item.name}` : '';
    }

    return {
      listAgencies,
      filterModel,
      isFilterActive,
      agenciesGroup,
      createOrEdit,
      deleteItem,
      listAgenciesGroup
    }
  }
}
</script>
<style lang="">
  
</style>